angular.module('TimelineCompaniesController', []).controller('TimelineCompaniesController', ['$scope', '$filter', 'Company', function($scope, $filter, Company) {

    $scope.logs = [];

    $scope.getLogs = function (id) {
        $scope.loading = true;
        Company.logs(id)
            .success(function (data) {
                $scope.logs = data.logs;
                $scope.loading = false;
            });
    };

}]);