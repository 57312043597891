angular.module('TasksController', []).controller('TasksController', ['$scope', '$timeout', 'Modal', 'User', 'Task', 'Company', '$location', function($scope, $timeout, Modal, User, Task, Company, $location) {

    var indexInTasksList; // Position de la tâche dans la liste générale des tâches (sans tri)
    var updateInProgress = true;
    $scope.noComments = true; // Permet de masquer les tâches dans les commentaires

    // Filtres
    $scope.filters = {};
    $scope.orderValues = [
        {value: 'due_to', label: 'Date d\'échéance'},
        {value: 'id', label: 'Date de création'},
        {value: '-state_designation', label: 'État'},
    ];
    $scope.states = [
        {value: '', label: 'Tous'},
        {value: 'todo', label: 'A faire'},
        {value: 'progress', label: 'En cours'},
        {value: 'done', label: 'Fait'},
    ];

    $scope.initFilters = function () {
        $scope.filters = {
            task: {
                designation: '',
                state_designation: '',
                archived_at: '!'
            },
            users: [],
            company_id: '!=',
            order: 'due_to',
            reverse: false,
        };
    };
    $scope.initFilters();

    // Initilisation
    $scope.newTask = {};
    $scope.tasks = [];
    $scope.task = {due:''};
    $scope.comments = [];
    $scope.logs = [];
    $scope.loading = false;

    // Initialisation de l'onglet par défaut affiché sur une tâche
    $scope.activeTab = 'details';

    // Initilisation des variables permettant l'édition des données
    function resetEditForm(){
        $scope.editDesignation = false;
        $scope.editDescription = false;
    }

    resetEditForm();

    // Récupération des informations utiles à l'administration d'une tâche
    $scope.users = [];
    User.listing().success(function (data) { $scope.users = data.users; });
    
    $scope.companies = [];
    Company.listing().success(function (data) {
        $scope.companies = data.companies;
        $scope.companiesFilter = angular.copy($scope.companies);
        $scope.companiesFilter.splice(0, 0, {id: '!=', name: 'Toutes'});
    });

    // Fonction permettant l'ouverture d'une tâche depuis l'URL
    $scope.openFormUrl = function () {
        var find = false;
        var show = $location.search().t;
        if (show) {
            for (var i = 0, len = $scope.tasks.length; i < len; i++) {
                if ($scope.tasks[i].id == show) {
                    $scope.show($scope.tasks[i]);
                    find = true;
                    break;
                }
            }
            if (!find) {
                sweetAlert("Oops...", "La tâche que vous demandez est introuvable", "error");
            }
        }
    };

    // Récupération de toutes les tâches
    $scope.get = function () {
        $scope.loading = true;
        Task.get().success(function (data) {
            $scope.tasks = data;
            $scope.openFormUrl();
            $scope.loading = false;
        });
    };

    // Récupération des tâches d'une société
    $scope.getCompanyTasks = function (id) {
        $scope.loading = true;
        Company.getTasks(id).success(function (data) {
            $scope.loading = false;
            $scope.company = data.company;
            $scope.tasks = data.tasks;
            $scope.openFormUrl();
        });
    };
    
    // Ajout d'une nouvelle tâche
    $scope.store = function () {
        $scope.task_errors = {};
        if ($scope.company) {
            $scope.newTask.company_id = $scope.company.id;
        }
        Task.store($scope.newTask)
            .success(function (data) {
                $scope.newTask = {};
                $scope.tasks.push(data.task);
                $scope.show(data.task);
            })
            .error(function (data) {
                $scope.task_errors = data;
            });
    };

    // Récupération des informations d'une tâche avant de l'afficher
    $scope.show = function (task) {
        updateInProgress = true;
        resetEditForm(); // On force la fermeture des formulaires
        indexInTasksList = $scope.tasks.indexOf(task); // Récupération de l'index de la tâche pour traitement ultérieur
        Task.show(task.id).success(function (data) {
            $scope.task     = data.task;
            $scope.comments = data.comments;

            $location.path('/').search({t: $scope.task.id}); // On ajoute dans l'url un paramètre pour le partage du lien de la tâche
            $scope.activeTab = 'details'; // On s'assure que l'onglet par défaut soit celui de détails
            $scope.selectedDate = data.task.due_to;
            Modal.open('showTaskModal');
            $timeout(function() { updateInProgress = false; }, 2000);
        });
    };


    // Mise à jour d'une tâche
    $scope.update = function () {
        updateInProgress = true;
        var datas = angular.copy($scope.task);
        $scope.e_errors = {};
        if ($scope.selectedDate) {
            datas.due_to = moment($scope.selectedDate).format('YYYY/MM/DD'); // On force le format de la date (nécessaire suite au datepicker)
        }
        Task.update(datas)
            .success(function (data) {
                $scope.tasks[indexInTasksList] = data.task;
                $scope.task = data.task;
                swal("Succès", "La tâche a été modidfiée", "success");
                resetEditForm();
                updateInProgress = false;
            })
            .error(function (data) {
                $scope.e_errors = data;
                updateInProgress = false;
            });
    };

    // Suppression d'une tâche
    $scope.delete = function (task) {
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            indexInTasksList = $scope.tasks.indexOf(task);
            Task.delete(task.id)
                .success(function (data) {
                    $scope.tasks.splice(indexInTasksList, 1);
                    Modal.close('showTaskModal');
                    swal("Succès", "La tâche a été supprimée", "success");
                });
        });

    };

    // Suppression d'un utilisateur d'une tâche
    $scope.deleteMember = function (userIndex) {
        var data = {};
        $scope.loadingMembers = true;
        $scope.task.users.splice(userIndex, 1);
        data.users = [];
        angular.forEach($scope.task.users, function(value, key){
            data.users.push(value.id);
        });
        data.task_id = $scope.task.id;

        Task.toggle_users(data).success(function (data) {
            $scope.task.users = data.users;
            $scope.tasks[indexInTasksList].users = data.users;
            $scope.loadingMembers = false;
        });
    };


    // Ajout d'un utilisateur à une tâche
    $scope.addMember = function ($item, $model, $label) {
        $scope.loadingMembers = true;
        $scope.member = null;
        var data = {};
        data.users = [];
        angular.forEach($scope.task.users, function(value, key){
            data.users.push(value.id);
        });
        data.users.push($item.id);
        data.task_id = $scope.task.id;

        Task.toggle_users(data).success(function (data) {
            $scope.task.users = data.users;
            $scope.tasks[indexInTasksList].users = data.users;
            $scope.loadingMembers = false;
        });
    };

    // Fonction de filtrages des utilisateurs
    $scope.notInMembers = function(item) {
        var display = true;
        angular.forEach($scope.task.users, function(value, key){
            if (value.id === item.id) {
                display = false;
            }
        });
        return display;
    };

    // Modification ou suppression de la société associée
    $scope.toggle_company = function (itemSelected, forDelete) {
        $scope.loadingCompany = true;
        var data = {};
        data.task_id = $scope.task.id;
        data.company_id = itemSelected.id;
        data.forDelete = forDelete;

        Task.toggle_company(data).success(function (data) {
            $scope.task.company = data.company;
            $scope.tasks[indexInTasksList].company = data.company;
            $scope.loadingCompany = false;

            if (data.delete) {
                $scope.task.company_id = null;
                $scope.tasks[indexInTasksList].company_id = null;
                swal("Succès", "La société a été dissociée de cette tâche", "success");
            } else {
                swal("Succès", "La société a été associée à cette tâche", "success");
            }

        });
    };

    // Fonctions pour changement d'état
    $scope.changeState = function (state, task) {
        indexInTasksList = $scope.tasks.indexOf(task);
        if (indexInTasksList === -1) {
            for (var i = 0, len = $scope.tasks.length; i < len; i++) {
                if ($scope.tasks[i].id == task.id) {
                    indexInTasksList = i;
                    break;
                }
            }
        }
        var data = {};
        data.id = $scope.tasks[indexInTasksList].id;
        data.state = state;

        Task.change_state(data)
            .success(function (data) {
                $scope.task = data.task;
                $scope.tasks[indexInTasksList] = data.task;
            });
    };

    // Fonction d'archivage
    $scope.archive = function (task) {
        indexInTasksList = $scope.tasks.indexOf(task);
        if (indexInTasksList === -1) {
            for (var i = 0, len = $scope.tasks.length; i < len; i++) {
                if ($scope.tasks[i].id == task.id) {
                    indexInTasksList = i;
                    break;
                }
            }
        }

        Task.archive($scope.tasks[indexInTasksList])
            .success(function (data) {
                $scope.task = data.task;
                $scope.tasks[indexInTasksList] = data.task;
            });
    };

    // Fonction pour suppression de l'échéance
    $scope.deleteDueDate = function () {
        $scope.task.due_to = null;
        $scope.update();
    };

    // Fonction pour récupération des logs d'une tâche
    $scope.getTaskLogs = function (task) {
        $scope.loadingLogs = true;
        $scope.logs = [];
        Task.logs(task.id)
            .success(function (data) {
                $scope.logs = data.logs;
                $scope.loadingLogs = false;
            });
    };

    // Suppression du paramètre dans l'url après fermeture de la modal
    $scope.resetParam = function () {
        $location.path('/').search('t', null);
    };

    $scope.$watch('selectedDate', function(newValue, oldValue) {
        if (newValue !== oldValue) {
            if (!updateInProgress) {
                $scope.update();
            }
        }
    }, true);
}]);