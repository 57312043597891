angular.module('CommentService', []).factory('Comment', ['$http', 'Config', function($http, Config) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/comments',
            });
        },
        getCommentsCompany: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/company/comments/' + id,
            });
        },
        store: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/comments',
                data: data,
            });
        },
        update: function(data) {
            return $http({
                method: 'PUT',
                url: Config.url() + '/api/comments/' + data.id,
                data: data,
            });
        },
        delete: function(id) {
            return $http({
                method: 'DELETE',
                url: Config.url() + '/api/comments/' + id,
            });
        },
    };

}]);