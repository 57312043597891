angular.module('CompaniesController', []).controller('CompaniesController', ['$scope', '$filter', 'Company', 'Category', 'User', 'Source', 'Sector', 'Config', function($scope, $filter, Company, Category, User, Source, Sector, Config) {

    /***********************************
    ---- Init ----
    ***********************************/
    $scope.employees = Config.getEmployees();

    $scope.company = {};
    $scope.contacts = [];
    $scope.addresses = [];
    $scope.categories = [];
    $scope.users = [];
    $scope.sources = [];
    $scope.sectors = [];

    Category.listing().success(function (data) { $scope.categories = data.categories; });
    User.listing().success(function (data) { $scope.users = data.users; });
    Source.listing().success(function (data) { $scope.sources = data.sources; });
    Sector.listing().success(function (data) { $scope.sectors = data.sectors; });

    // Récupération des informations d'une société
    $scope.getCompany = function (id) {
        $scope.loadingCompany = true;
        Company.getCompany(id).success(function (data) {
            $scope.loadingCompany   = false;
            $scope.company   = data.company;
            $scope.contacts  = data.contacts;
            $scope.addresses = data.addresses;

            $scope.$parent.company  = data.company;
            if ($scope.$parent.limitComments) {
                $scope.$parent.comments = data.comments;
            }
            $scope.$parent.tasks    = data.tasks;
        });
    };

    // Mise à jour de la société
    $scope.update = function (data, id) {
        $scope.errors = {};
        data.id = id;
        Company.update(data)
            .success(function (data) {
                $scope.errors = {};
                $scope.company = data.company;
                swal("Succès", "La société a été modifiée", "success");
            })
            .error(function (data) {
                $scope.editableForm.$show();
                $scope.errors = data;
                swal("Oupss..", "Impossible de mettre à jour la société, les informations fournies sont incorrectes", "error");
            });
    };

    // Suppression de la société
    $scope.delete = function (id) {
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            Company.delete(id).success(function (data) {
                swal({
                    title: "Succès",
                    text: "La société a été supprimée",
                    type: "success"
                }, function(){
                    // Redirection vers listing des sociétés
                    window.location.replace(Config.url() + '/companies');
                });
            });
        });
    };

    // Restauration d'une société
    $scope.restore = function () {
        Company.restore($scope.company.id).success(function (data) {
            $scope.company = data.company;
            swal("Succès", "La société a été restaurée", "success");
            location.reload();
        });
    };
}]);