angular.module('ContactService', []).factory('Contact', ['$http', 'Config', function($http, Config) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/contacts',
            });
        },
        getContactCompany: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/company/contacts/' + id,
            });
        },
        store: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/contacts',
                data: data,
            });
        },
        update: function(data) {
            return $http({
                method: 'PUT',
                url: Config.url() + '/api/contacts/' + data.id,
                data: data,
            });
        },
        delete: function(id) {
            return $http({
                method: 'DELETE',
                url: Config.url() + '/api/contacts/' + id,
            });
        },
    };

}]);