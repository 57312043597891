angular.module('ContactTypesController', []).controller('ContactTypesController', ['$scope', 'ContactType', 'Modal', function($scope, ContactType, Modal) {

    $scope.contactTypes = [];

    // Init new contact type
    $scope.initNewContactType = function () {
        $scope.newContactType = {};
    };

    // Retrieve list of contact types
    $scope.getContactType = function () {
        $scope.loading = true;
        ContactType.get().success(function (data) {
            $scope.loading = false;
            $scope.contactTypes = data;
        });
    };

    // Store a new contact type
    $scope.store = function () {
        ContactType.store($scope.newContactType)
            .success(function (data) {
                $scope.initNewContactType();
                $scope.errors = {};
                $scope.contactTypes = data.contactTypes;
                swal("Succès", "La fonction a été ajoutée", "success");
            })
            .error(function (data) {
                $scope.errors = data;
            });
    };

    // Open form editing modal
    $scope.edit = function (contactType) {
        $scope.editedContactType = angular.copy(contactType);
        $scope.e_errors={};
        Modal.open('editModal');
    };

    // Update a contact type
    $scope.update = function () {
        ContactType.update($scope.editedContactType)
            .success(function (data) {
                Modal.close('editModal');
                $scope.editedContactType = {};
                $scope.e_errors = {};
                $scope.contactTypes = data.contactTypes;
                swal("Succès", "La fonction a été modifiée", "success");
            })
            .error(function (data) {
                $scope.e_errors = data;
            });
    };

    // Destroy a contact type
    $scope.delete = function (id) {
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            ContactType.delete(id).success(function (data) {
                $scope.contactTypes = data.contactTypes;
                swal("Succès", "La fonction a été supprimée", "success");
            });
        });
    };

    // Restore a contact type
    $scope.restore = function (id) {
        ContactType.restore(id).success(function (data) {
            $scope.contactTypes = data.contactTypes;
            swal("Succès", "La fonction a été restaurée", "success");
        });
    };

}]);