angular.module('CommentsCompaniesController', []).controller('CommentsCompaniesController', ['$scope', '$filter', 'Company', 'User', function($scope, $filter, Company, User) {

    $scope.company = {};
    $scope.comments = [];
    $scope.users = [];
    $scope.commentsFilter = {};
    
    // Initialisation des filtres
    $scope.initCommentsFilter = function () {
        $scope.commentsFilter = {
            user_id: '',
            task_id: '!=',
            interaction_id: '!='
        };
    };

    $scope.initCommentsFilter();

    // Récupération de la liste des utilisateurs pour filtres
    User.listing().success(function (data) {
        $scope.users = data.users;
        $scope.users.splice(0, 0, {id: '', full_name: 'Tous'});
    });

    // Récupération des commentaires d'une société
    $scope.getCompanyComments = function (id) {
        $scope.loading = true;
        Company.getCompanyComments(id).success(function (data) {
            $scope.loading = false;
            $scope.company = data.company;
            $scope.comments = data.comments;
        });
    };

}]);