angular.module('SourcesController', []).controller('SourcesController', ['$scope', 'Source', 'Modal', function($scope, Source, Modal) {
    var index;
    $scope.sources = [];

    // Initilisation d'une nouvelle source
    $scope.initNewSource = function () {
        $scope.newSource = {};
    };

    // Récupération de la liste des sources
    $scope.getSources = function () {
        $scope.loading = true;
        Source.get().success(function (data) {
            $scope.loading = false;
            $scope.sources = data;
        });
    };

    // Ajout d'une nouvelle source
    $scope.store = function () {
        $scope.errors = {};
        Source.store($scope.newSource)
            .success(function (data) {
                $scope.initNewSource();
                $scope.sources.push(data.source);
                swal("Succès", "La source a été ajoutée", "success");
            })
            .error(function (data) {
                $scope.errors = data;
            });
    };

    // Ouverture modal d'édition
    $scope.edit = function (source) {
        index = $scope.sources.indexOf(source);
        $scope.editedSource = angular.copy(source);
        $scope.e_errors={};
        Modal.open('editModal');
    };

    // Mise à jour d'une source
    $scope.update = function () {
        $scope.e_errors = {};
        Source.update($scope.editedSource)
            .success(function (data) {
                $scope.editedSource = {};
                $scope.sources[index] = data.source;
                Modal.close('editModal');
                swal("Succès", "La source a été modifiée", "success");
                index = null;
            })
            .error(function (data) {
                $scope.e_errors = data;
            });
    };

    // Suppression d'une source
    $scope.delete = function (source) {
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            index = $scope.sources.indexOf(source);
            Source.delete(source.id).success(function (data) {
                $scope.sources[index] = data.source;
                swal("Succès", "La source a été supprimée", "success");
                index = null;
            });
        });
    };

    // Restauration d'une source
    $scope.restore = function (source) {
        index = $scope.sources.indexOf(source);
        Source.restore(source.id).success(function (data) {
            $scope.sources[index] = data.source;
            swal("Succès", "La source a été restaurée", "success");
            index = null;
        });
    };

}]);