angular.module('CommentsController', []).controller('CommentsController', ['$scope', 'Comment', function($scope, Comment) {

    $scope.new = {};

    // Initialisation d'une nouveeau commentaire
    $scope.initNew= function () {
        $scope.new = {};
    };

    // Mise en état d'édition d'une tâche
    $scope.edit = function (index) {
        $scope.$parent.comments[index].edited = true;
    };

    // Modification d'un commentaire
    $scope.update = function (index) {
        $scope.e_errors = {};
        Comment.update($scope.$parent.comments[index])
            .success(function (data) {
                $scope.$parent.comments[index] = data.comment;
            })
            .error(function (data) {
                $scope.e_errors = data;
            });
    };

    // Insertion d'un nouveau commentaire
    $scope.store = function () {
        $scope.errors = {};
        if ($scope.$parent.company) {
            $scope.new.company_id = $scope.$parent.company.id;
        }
        if ($scope.$parent.task) {
            if ($scope.$parent.task.company) {
                $scope.new.company_id = $scope.$parent.task.company.id;
            }
            $scope.new.task_id = $scope.$parent.task.id;
        }


        Comment.store($scope.new)
            .success(function (data) {
                $scope.initNew();
                $scope.$parent.comments.unshift(data.comment);
            })
            .error(function (data) {
                $scope.errors = data;
            });
    };

    // Suppression d'un commentaire
    $scope.delete = function (index) {
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            Comment.delete($scope.$parent.comments[index].id)
                .success(function (data) {
                    $scope.$parent.comments.splice(index, 1);
                    swal("Succès", "Le commentaire a été supprimé", "success");
                });
        });

    };

}]);