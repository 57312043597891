angular.module('AddressesController', []).controller('AddressesController', ['$scope', 'Address', 'Modal', function($scope, Address, Modal) {

    $scope.address = {};

    // Initialisation d'une nouvelle adresse
    $scope.initAddress = function () {
        $scope.address = {};
    };

    // Ouverture modal de création
    $scope.create = function () {
        $scope.initAddress();
        $scope.errors={};
        Modal.open('createModalAddress');
    };

    // Ouverture de la modal de visualisation
    $scope.show = function (address) {
        $scope.displayedAddress = address;
        Modal.open('showModalAddress');
    };

    // Ouverture modal d'édition
    $scope.edit = function (address, index) {
        $scope.index = index;
        $scope.editedAddress = angular.copy(address);
        $scope.e_errors={};
        Modal.open('editModalAddress');
    };

    // Mise à jour d'une adresse
    $scope.update = function () {
        Address.update($scope.editedAddress)
            .success(function (data) {
                $scope.editedAddress = {};
                $scope.e_errors = {};
                $scope.$parent.addresses[$scope.index] = data.address;
                Modal.close('editModalAddress');
                swal("Succès", "L'adresse a été modifiée", "success");
            })
            .error(function (data) {
                $scope.e_errors = data;
            });
    };

    // Ajout d'une nouvelle adresse
    $scope.store = function () {
        $scope.address.company_id = $scope.$parent.company.id;
        Address.store($scope.address)
            .success(function (data) {
                $scope.initAddress();
                $scope.errors = {};
                $scope.$parent.addresses.push(data.address);
                Modal.close('createModalAddress');
                swal("Succès", "L'adresse a été ajoutée", "success");
            })
            .error(function (data) {
                $scope.errors = data;
            });
    };

    // Suppression d'une adresse
    $scope.delete = function (index) {
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            Address.delete($scope.$parent.addresses[index].id).success(function (data) {
                $scope.$parent.addresses.splice(index, 1);
                swal("Succès", "L'adresse a été supprimée", "success");
            });
        });

    };

}]);