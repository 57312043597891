angular.module('TaskService', []).factory('Task', ['$http', 'Config', function($http, Config) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/tasks',
            });
        },
        show: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/tasks/' + id,
            });
        },
        update: function(data) {
            return $http({
                method: 'PUT',
                url: Config.url() + '/api/tasks/' + data.id,
                data: data,
            });
        },
        store: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/tasks',
                data: data,
            });
        },
        delete: function(id) {
            return $http({
                method: 'DELETE',
                url: Config.url() + '/api/tasks/' + id,
            });
        },
        restore: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/tasks/restore/' + id,
            });
        },
        change_state: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/tasks/change_state',
                data: data,
            });
        },
        toggle_users: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/tasks/toggle_users',
                data: data,
            });
        },
        toggle_company: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/tasks/toggle_company',
                data: data,
            });
        },
        archive: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/tasks/archive',
                data: data,
            });
        },
        logs: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/tasks/' + id + '/timeline',
            });
        },
    };

}]);