// ANGULAR JS
var app = angular.module('app', [
    'MainController',
    'DashboardController',
    'DashboardService',
    'StatisticsController',
    'StatisticService',
    'IndexInteractionsController',
    'InteractionService',
    'CreateInteractionController',
    'EditInteractionController',
    'ModalService',
    'UsersController',
    'UserService',
    'RolesController',
    'RoleService',
    'CategoriesController',
    'CategoryService',
    'CompaniesController',
    'ShowCompaniesController',
    'IndexCompaniesController',
    'CreateCompaniesController',
    'ActivityCompaniesController',
    'CommentsCompaniesController',
    'TimelineCompaniesController',
    'CompanyService',
    'ContactsController',
    'ContactService',
    'AddressesController',
    'AddressService',
    'CommentsController',
    'CommentService',
    'SourcesController',
    'SourceService',
    'ContactTypesController',
    'ContactTypeService',
    'SectorsController',
    'SectorService',
    'EventsController',
    'EventService',
    'ActivitiesController',
    'ConfigService',
    'xeditable',
    'TasksController',
    'TaskService',
    'angularUtils.directives.dirPagination',
    'ui.bootstrap',
    'pickadate',
    'angularjs-dropdown-multiselect',
    'ngSanitize',
    'angularMoment',
    'chart.js',
    'monospaced.elastic'
]);

app.run(['editableOptions', function(editableOptions) {
    editableOptions.theme = 'default';
}]);

app.run(['amMoment', function(amMoment) {
    amMoment.changeLocale('fr');
}]);

app.config(['paginationTemplateProvider', '$httpProvider', 'ChartJsProvider', function(paginationTemplateProvider, $httpProvider, ChartJsProvider) {
    var path = $('body').data('url')
    paginationTemplateProvider.setPath(path + '/template/pagination.blade.php');
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    ChartJsProvider.setOptions({
        scaleStartValue : 0,
        chartColors: ['#0588d0', '#4A535A'],
        responsive: true,
        animation: false,
        scales: {
            yAxes: [{
                display: true,
                ticks: {
                    min: 0,
                    stepSize: 2,
                }
            }]
        }
    });
}]);

app.directive("collapseTable", function() {
    return {
        restrict: "A",
        link: function(scope, elem, attrs) {
            $(elem).click(function(e) {
                e.preventDefault();

                var el = $(elem);
                var parent = el.closest("tr");
                var nextTr = parent.next();

                parent.toggleClass("no-border-bottom");
                nextTr.toggleClass("hide");
            });
        }
    }
});

// format content field
app.filter('nl2br', ['$sce', function($sce){
    return function(msg,is_xhtml) {
        var is_xhtml = is_xhtml || true;
        var breakTag = (is_xhtml) ? '<br />' : '<br>';
        var msg = (msg + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
        return $sce.trustAsHtml(msg);
    }
}]);

// Retourne uniquement les élèments non supprimés
app.filter('notDeleted', ['$filter', function($filter){
    return function(input){
        return $filter('filter')(input, {deleted_at: null});
    }
}]);

// Filtres sur les membres d'une tâche
app.filter('dateComparator', function(){
    return function( input, filterDate, comparator, property ) {
        var out = [];

        if (filterDate) {
            var date = new moment(filterDate);
            date = date.format('YYYY-MM-DD');

            angular.forEach(input, function(element){
                if (comparator == '>') {
                    if (element[property] > date) {
                        out.push(element);
                    }
                }
                if (comparator == '<') {
                    if (element[property] < date) {
                        out.push(element);
                    }
                }
            });
        } else {
            out = input;
        }
        return out;
    };
});

app.filter('hasMembers', function(){
    return function( input, users ) {
        var out = []; // Tableaux de sortie
        
        if (users.length) {
            // Pour chaque tâche
            angular.forEach(input, function(element){
                // Construction tableau avec les id des utilisateurs de l'élément
                var ids = [];
                angular.forEach(element.users, function(user){
                    ids.push(user.id);
                });

                // Construction tableau avec les id des utilisateurs sélectionnés dans les filtres
                var filterIds = [];
                angular.forEach(users, function(user, key){
                    filterIds.push(user.id);
                });
                var filterIdsLength = filterIds.length;

                var idsFind = 0;
                for (var i = 0; i < filterIds.length; i++) {
                    for (var j = 0; j < ids.length; j++) {
                        if (ids[j] == filterIds[i]) {
                            idsFind ++;
                        }
                    }
                }

                // Si on trouve tout les utilisateurs sélectionnés dans la liste des utilisateurs de la tâche
                if (filterIdsLength == idsFind) {
                    out.push(element);
                }
            });
        } else {
            out = input;
        }

        return out;
    };
});

app.filter('split', function() {
    return function(input, splitChar, splitIndex) {
        return input.split(splitChar)[splitIndex];
    }
});

app.filter("asDate", function () {
    return function (input) {
        return new Date(input);
    }
});

// Configuration globale
$(function() {
    function clone(obj){
        try{
            var copy = JSON.parse(JSON.stringify(obj));
        } catch(ex){
            alert("Erreur compatibilité");
        }
        return copy;
    }

    /***********************************
    ---- Datepicker ----
    ***********************************/
    var options = {
        monthsFull: [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
        monthsShort: [ 'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec' ],
        weekdaysFull: [ 'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi' ],
        weekdaysShort: [ 'Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam' ],
        today: 'Aujourd\'hui',
        clear: 'Effacer',
        close: 'Fermer',
        firstDay: 1,
        format: 'dd mmmm yyyy',
        //format: 'yyyy/mm/dd',
        formatSubmit: 'yyyy/mm/dd',
        labelMonthNext:"Mois suivant",
        labelMonthPrev:"Mois précédent",
        labelMonthSelect:"Sélectionner un mois",
        labelYearSelect:"Sélectionner une année",
    };

    var options_max = clone(options);
    options_max.max = true;

    $('.datepicker-limited').pickadate(options_max);
    $('.datepicker').pickadate(options);

    /**************************************************
    ---- On vérifie la présence d'une sidebar ----
    **************************************************/
    var sidebar = $('#sidebar');
    if (!sidebar.length) {
        $('#sidebar-icon').hide().remove();
    }

    
    // VARIABLES
    var width = $(window).width();
    var icon_menu = $('.topbar-menu');
    var link_sidebar = $('.link-sidebar');

    if (width < 1040) {
        icon_menu.removeClass('open');
        $('#menu').toggleClass('close');
        $('#sidebar').toggleClass('close');
        $('body').toggleClass('menu-open');
    }

    icon_menu.click(function (e) {
        e.preventDefault();
        icon_menu.toggleClass('open');
        $('#menu').toggleClass('close');
        $('body').toggleClass('menu-open');
    });

    link_sidebar.click(function (e) {
        e.preventDefault();
        $('#sidebar').toggleClass('close');
    });

    /*$(window).resize(function(){
        if ($(window).width() < 1040) {
            if ($('#menu').hasClass('open')) {
                $('#menu').removeClass('open');
                $('.topbar-menu').removeClass('open');
            }
            if ($('#sidebar').hasClass('open')) {
                $('#sidebar').removeClass('open');
            }
        } else {
            $('#site-content').removeClass('full');
            $('.page-content').addClass('with-sidebar');
            $('#menu').addClass('open');
            $('.topbar-menu').addClass('open');
            $('#sidebar').addClass('open');
        }
    });*/

    // DROPDOWN
    function closeAllDropdown() {
        var dropdowns = $('body').find('.dropdown-menu');
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            jQuery(openDropdown).removeClass('show');
        }
    }

    $('.dropdown-item').on('click', function (e){
        e.preventDefault();
        e.stopPropagation();

        var parent = $(this).parent();
        var dropdown_menu = parent.find('.dropdown-menu');

        if(dropdown_menu.hasClass('show')) {
            dropdown_menu.removeClass('show');
        } else {
            closeAllDropdown();
            dropdown_menu.addClass('show');
        }


    });

    // Fermeture des dropdown si clic dans le document
    $(document).click(function(){
        closeAllDropdown();
    });

    //--------- MODAL ---------//
    $('.openModal').on('click', function (e){
        alert('ok');
        e.preventDefault();
        var cible = $(this).data('modal');
        var modal = $('#'+cible);

        modal.show();
    });

    // Ouverture des onglets responsive
    $('#tabs-menu').on('click', function (e){
        e.preventDefault();
        $('#tabs').toggleClass('col-s-hide');
    });

    // Fermeture de la modal au click dans la fenetre
    window.onclick = function(event) {
        var target = jQuery(event.target);

        if (target.hasClass('modal')) {
            $("body").removeClass("modal-open");
            target.hide();
        }

        if (target.hasClass('closeModal')) {
            var modal = target.closest('.modal');
            $("body").removeClass("modal-open");
            modal.hide();
        }
    };

    //  Au focus
    $('.input').focus(function(){
        $(this).parent().addClass('focus');
    });

    // à la perte du focus
    $('.input').blur(function(){
        $(this).parent().removeClass('focus');
    });

});