angular.module('IndexInteractionsController', []).controller('IndexInteractionsController', ['$scope', 'Interaction', 'Company', 'Event', 'User', function($scope, Interaction, Company, Event, User) {

    $scope.inController = 'interactions';

    var index = null;

    $scope.interactions = [];
    $scope.paginationChoice = [ 50, 100, 150, 200];

    $scope.initFilters = function () {
        $scope.filters = {
            interaction: {
                company_id: '!',
                event_id: '!',
                user_id: '!',
            },
            showComments: false,
            perPage: 100,
            startDate: null,
            endDate: null,
        };
    };
    $scope.initFilters();

    Company.listing().success(function (data) {
        $scope.companies = data.companies;
        $scope.companiesFilter = angular.copy($scope.companies);
        $scope.companiesFilter.splice(0, 0, {id: '!', name: 'Toutes'});
    });

    Event.listing().success(function (data) {
        $scope.events = data.events;
        $scope.eventsFilter = angular.copy($scope.events);
        $scope.eventsFilter.splice(0, 0, {id: '!', designation: 'Tous'});
    });

    User.listing().success(function (data) {
        $scope.users = data.users;
        $scope.usersFilter = angular.copy($scope.users);
        $scope.usersFilter.splice(0, 0, {id: '!', full_name: 'Tous'});
    });

    // Récupération des intéractions
    $scope.get = function () {
        $scope.loadingInteractions = true;
        Interaction.get().success(function (data) {
            $scope.loadingInteractions = false;
            $scope.interactions = data;
        });
    };

    $scope.get();

    $scope.create = function () {
        $scope.$broadcast('createNewInteraction');
    };

    $scope.edit = function (interaction) {
        $scope.$broadcast('editInteraction', interaction);
    };

    // Suppression d'une intéraction
    $scope.delete = function (interaction) {
        index = $scope.interactions.indexOf(interaction);
        console.log(index);
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            Interaction.delete($scope.interactions[index].id).success(function (data) {
                $scope.interactions.splice(index, 1);
                index = null;
                swal("Succès", "L'interaction a été supprimée", "success");
            });
        });
    };
}]);