angular.module('CompanyService', []).factory('Company', ['$http', 'Config', function($http, Config) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/companies',
            });
        },
        listing: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/companies/listing',
            });
        },
        getCompany: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/companies/' + id,
            });
        },
        getCompanyActivity: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/companies/' + id + '/interactions',
            });
        },
        getCompanyComments: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/companies/' + id + '/comments',
            });
        },
        getTasks: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/companies/' + id + '/tasks',
            });
        },
        update: function(data) {
            return $http({
                method: 'PUT',
                url: Config.url() + '/api/companies/' + data.id,
                data: data,
            });
        },
        store: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/companies',
                data: data,
            });
        },
        delete: function(id) {
            return $http({
                method: 'DELETE',
                url: Config.url() + '/api/companies/' + id,
            });
        },
        restore: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/companies/restore/' + id,
            });
        },
        logs: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/companies/' + id + '/timeline',
            });
        },
    };

}]);