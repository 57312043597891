angular.module('CategoryService', []).factory('Category', ['$http', 'Config', function($http, Config) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/categories',
            });
        },
        listing: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/categories/listing',
            });
        },
        store: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/categories',
                data: data,
            });
        },
        update: function(data) {
            return $http({
                method: 'PUT',
                url: Config.url() + '/api/categories/' + data.id,
                data: data,
            });
        },
        delete: function(id) {
            return $http({
                method: 'DELETE',
                url: Config.url() + '/api/categories/' + id,
            });
        },
        restore: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/categories/restore/' + id,
            });
        },
        swap: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/categories/swap',
                data: data,
            });
        },
    };

}]);