angular.module('EventsController', []).controller('EventsController', ['$scope', 'Event', 'Modal', function($scope, Event, Modal) {
    var index;
   $scope.events = [];

    // Initialisation d'un nouvel évènement
    $scope.initNewEvent = function () {
        $scope.newEvent = {};
    };

    // Récupération de la liste des évènements
    $scope.getEvents = function () {
        $scope.loading = true;
        Event.get().success(function (data) {
            $scope.loading = false;
            $scope.events = data;
        });
    };

    // Ajout d'un nouvel évènement
    $scope.store = function () {
        $scope.errors = {};
        Event.store($scope.newEvent)
            .success(function (data) {
                $scope.initNewEvent();
                $scope.events.push(data.event);
                swal("Succès", "L'évènement a été ajouté", "success");
            })
            .error(function (data) {
                $scope.errors = data;
            });
    };

    // Ouverture modal d'édition
    $scope.edit = function (event) {
        index = $scope.events.indexOf(event);
        $scope.editedEvent = angular.copy(event);
        $scope.e_errors={};
        Modal.open('editModal');
    };

    // Mise à jour d'un évènement
    $scope.update = function () {
        $scope.e_errors = {};
        Event.update($scope.editedEvent)
            .success(function (data) {
                $scope.editedEvent = {};
                $scope.events[index] = data.event;
                swal("Succès", "L'évènement a été modifé", "success");
                Modal.close('editModal');
                index = null;
            })
            .error(function (data) {
                $scope.e_errors = data;
            });
    };

    // Suppression d'un évènement
    $scope.delete = function (event) {
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            index = $scope.events.indexOf(event);
            Event.delete(event.id).success(function (data) {
                $scope.events[index] = data.event;
                swal("Succès", "L'évènement a été supprimé", "success");
                index = null;
            });
        });
    };

    // Restauration d'un évènement
    $scope.restore = function (event) {
        index = $scope.events.indexOf(event);
        Event.restore(event.id).success(function (data) {
            $scope.events[index] = data.event;
            swal("Succès", "L'évènement a été restauré", "success");
            index = null;
        });
    };

}]);