angular.module('ActivityCompaniesController', []).controller('ActivityCompaniesController', ['$scope', '$filter', 'Company', 'User', 'Event', 'Modal', 'Interaction', function($scope, $filter, Company, User, Event, Modal, Interaction) {

    $scope.inController = 'interactions';
    var index = null;
    
    $scope.company = {};
    $scope.users = [];
    $scope.events = [];

    $scope.paginationChoice = [ 50, 100, 150, 200];

    $scope.initFilters = function () {
        $scope.filters = {
            interaction: {
                company_id: '!',
                event_id: '!',
                user_id: '!',
            },
            showComments: false,
            perPage: 100,
            startDate: null,
            endDate: null,
        };
    };
    $scope.initFilters();

    Event.listing().success(function (data) {
        $scope.events = data.events;
        $scope.eventsFilter = angular.copy($scope.events);
        $scope.eventsFilter.splice(0, 0, {id: '!', designation: 'Tous'});
    });

    User.listing().success(function (data) {
        $scope.users = data.users;
        $scope.usersFilter = angular.copy($scope.users);
        $scope.usersFilter.splice(0, 0, {id: '!', full_name: 'Tous'});
    });

    // Récupération de l'activité d'une société
    $scope.getCompanyActivity = function (id) {
        $scope.loading = true;
        Company.getCompanyActivity(id).success(function (data) {
            $scope.loading = false;
            $scope.company = data.company;
            $scope.interactions = data.interactions;
        });
    };

    // Ouverture modal de création d'une intéraction
    $scope.createInteraction = function () {
        $scope.$broadcast('createNewInteraction');
    };

    $scope.edit = function (interaction) {
        $scope.$broadcast('editInteraction', interaction);
    };

    // Suppression d'une intéraction
    $scope.delete = function (interaction) {
        index = $scope.interactions.indexOf(interaction);
        console.log(index);
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            Interaction.delete($scope.interactions[index].id).success(function (data) {
                $scope.interactions.splice(index, 1);
                index = null;
                swal("Succès", "L'interaction a été supprimée", "success");
            });
        });
    };

}]);