angular.module('DashboardService', []).factory('Dashboard', ['$http', 'Config', function($http, Config) {
    
    return {
        tasks: function(data) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/dashboard/tasks',
            });
        },
        interactions: function(data) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/dashboard/interactions',
            });
        },
    };

}]);