angular.module('UsersController', []).controller('UsersController', ['$scope', 'User', 'Role', 'Modal', function($scope, User, Role, Modal) {

    $scope.users = [];
    $scope.userFilter = {};
    var index;

    // Filtres
    $scope.initFilters = function () {
        $scope.filters = {
            user: {},
            deleted: '!'
        };
    };

    $scope.initFilters();

    // Récupération des rôles
    Role.get().success(function (data) {
        $scope.roles  = data;
    });

    // Récupération de l'utilisateur connecté
    User.getAuth()
        .success(function (data) {
            $scope.userConnected = data;
        });

    // Initialise un nouvel utilisateur
    $scope.initUser = function () {
        $scope.user = {
            color: 'gris',
            role_id: angular.copy($scope.roles[1].id)
        };
    };

    // Récupération de la liste des utilisateurs
    $scope.get = function () {
        $scope.loading = true;
        User.get().success(function (data) {
            $scope.loading = false;
            $scope.users = data;
        });
    };

    // Ouverture modal de création
    $scope.create = function () {
        $scope.initUser();
        $scope.errors={};
        Modal.open('createModal');
    };

    // Insertion d'un nouvel utilisateur
    $scope.store = function () {
        $scope.errors = {};
        User.store($scope.user)
            .success(function (data) {
                $scope.initUser();
                $scope.users.push(data.user);
                Modal.close('createModal');
                swal("Succès", "L'utilisateur a été ajouté", "success");
            })
            .error(function (data) {
                $scope.errors = data;
            });
    };

    // Ouverture de la modal d'édition
    $scope.edit = function (user) {
        index = $scope.users.indexOf(user);
        $scope.editedUser = angular.copy(user);
        $scope.editedUser.role_id = angular.copy(user.roles[0].id);
        $scope.e_errors={};
        Modal.open('editModal');
    };

    // Modification d'un utilisateur
    $scope.update = function () {
        $scope.e_errors = {};
        User.update($scope.editedUser)
            .success(function (data) {
                $scope.editedUser = {};
                $scope.users[index] = data.user;
                Modal.close('editModal');
                swal("Succès", "L'utilisateur a été modifié", "success");
            })
            .error(function (data) {
                $scope.e_errors = data;
            });
    };

    // Suppression d'un utilisateur
    $scope.delete = function (user) {
        swal({
            title: "Êtes vous sur ?",
            text: "L'utilisateur sera récupérable à l'aide de la fonction restaurer",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            index = $scope.users.indexOf(user);
            User.delete(user.id).success(function (data) {
                $scope.users[index] = data.user;
                swal("Succès", "L'utilisateur a été supprimé", "success");
            });
        });

    };

    // Restauration d'un utilisateur
    $scope.restore = function (user) {
        index = $scope.users.indexOf(user);
        User.restore(user.id).success(function (data) {
            $scope.users[index] = data.user;
            swal("Succès", "L'utilisateur a été restauré", "success");
        });
    };
}]);