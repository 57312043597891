angular.module('CreateInteractionController', []).controller('CreateInteractionController', ['$scope', '$filter', 'Interaction', 'Modal', 'Event', 'User', function($scope, $filter, Interaction, Modal, Event, User) {

    $scope.newInteraction = {
        created_at: new Date()
    };
    $scope.addEvent = false;

    $scope.initInteraction = function () {
        $scope.newInteraction = {
            user_id: $scope.$parent.users[0].id,
            event_id: $scope.$parent.events[0].id,
            created_at: new Date()
        }
        if ($scope.$parent.company) {
            $scope.newInteraction.company_id = $scope.$parent.company.id;
        } else {
            $scope.newInteraction.company_id = $scope.$parent.companies[0].id;
        }
    };

    $scope.storeEvent = function () {
        $scope.event_errors = {};
        Event.store($scope.newEvent)
            .success(function (data) {
                $scope.addEvent = false;
                $scope.$parent.events.splice(0, 0, data.event);
                $scope.$parent.eventsFilter.push(data.event);
                $scope.newInteraction.event_id = data.event.id;
                $scope.newEvent = {};
            })
            .error(function (data) {
                $scope.event_errors = data;
            });
    };

    // Ouverture modal de création
    $scope.$on('createNewInteraction', function(event, args) {
        Modal.open('createInteractionModal');
    });

    // Ajout d'une nouvelle intéraction
    $scope.storeInteraction = function () {
        $scope.errors = {};

        if ($scope.$parent.company) {
            $scope.newInteraction.company_id = $scope.$parent.company.id;
        }
        $scope.newInteraction.created_at = $filter('date')($scope.newInteraction.created_at, "yyyy-MM-dd");

        Interaction.store($scope.newInteraction)
            .success(function (data) {
                $scope.initInteraction();
                Modal.close('createInteractionModal');
                swal("Succès", "L'intéraction a été ajoutée", "success");

                // Cas: depuis liste des intéractions
                if ($scope.$parent.inController == 'interactions') {
                    $scope.$parent.interactions.push(data.interaction);
                    $scope.$parent.interactions = $filter('orderBy')($scope.$parent.interactions, '-created_at');
                }

                // Cas: depuis dashboard
                if ($scope.$parent.inController == 'dashboard') {
                    $scope.$parent.initDashboard();
                }
            })
            .error(function (data) {
                $scope.errors = data;
            });
    };

    // Récupération des informations depuis le controller parent
    $scope.$watch('$parent.users', function (newValue, oldValue) {
        if (newValue) {
            if (newValue.length) {
                User.getAuth().success(function (data) {
                    $scope.userConnected = data;
                    if ($scope.userConnected.id == 1) {
                        $scope.newInteraction.user_id = $scope.$parent.users[0].id;
                    } else {
                        $scope.newInteraction.user_id = $scope.userConnected.id;
                    }
                });
            }
        }
    });

    $scope.$watch('$parent.companies', function (newValue, oldValue) {
        if (newValue) {
            if (newValue.length) {
                $scope.newInteraction.company_id = $scope.$parent.companies[0].id;
            }
        }
    });

    $scope.$watch('$parent.events', function (newValue, oldValue) {
        if (newValue) {
            if (newValue.length) {
                $scope.addEvent = false;
                $scope.newInteraction.event_id = $scope.$parent.events[0].id;
            } else {
                $scope.addEvent = true;
            }
        }
    });
}]);