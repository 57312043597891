angular.module('AddressService', []).factory('Address', ['$http', 'Config', function($http, Config) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/addresses',
            });
        },
        getAddressCompany: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/company/addresses/' + id,
            });
        },
        store: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/addresses',
                data: data,
            });
        },
        update: function(data) {
            return $http({
                method: 'PUT',
                url: Config.url() + '/api/addresses/' + data.id,
                data: data,
            });
        },
        delete: function(id) {
            return $http({
                method: 'DELETE',
                url: Config.url() + '/api/addresses/' + id,
            });
        },
    };

}]);