angular.module('ShowCompaniesController', []).controller('ShowCompaniesController', ['$scope', '$filter', 'Company', 'User', 'Config', 'Task', function($scope, $filter, Company, User, Config, Task) {

    /***********************************
    ---- Init ----
    ***********************************/
    $scope.filters = {
        users: []
    };
    $scope.limitComments = 3;
    $scope.limitTasks = 5;

    $scope.reduceActions = true; // Masque les icones d'actions d'une tâche et réduit la place occupée
    $scope.linkInTask = true; // Permet d'afficher un lien vers une tâche plutôt que de lancer l'action show

    // Ajout d'une nouvelle tâche
    $scope.store = function () {
        $scope.task_errors = {};
        $scope.newTask.company_id = $scope.company.id;
        Task.store($scope.newTask)
            .success(function (data) {
                $scope.task = {};
                swal({
                    title: "Succès",
                    text: "La tâche a été ajoutée. Vous allez être redirigé vers sa page de visualisation",
                    type: "success"
                }, function(){
                    window.location.replace(Config.url() + '/companies/' + data.task.company_id + '/tasks#/?t=' + data.task.id);
                });
            })
            .error(function (data) {
                $scope.task_errors = data;
            });
    };
}]);