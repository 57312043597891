angular.module('SectorsController', []).controller('SectorsController', ['$scope', 'Sector', 'Modal', function($scope, Sector, Modal) {
    var index;
    $scope.sectors = [];

    // Initilisation d'un nouveau secteur
    $scope.initNewSector = function () {
        $scope.newSector = {};
    };

    // Récupération de la liste des sources
    $scope.getSectors = function () {
        $scope.loading = true;
        Sector.get().success(function (data) {
            $scope.loading = false;
            $scope.sectors = data;
        });
    };

    // Ajout d'une nouveau secteur
    $scope.store = function () {
        $scope.errors = {};
        Sector.store($scope.newSector)
            .success(function (data) {
                $scope.initNewSector();
                $scope.sectors.push(data.sector);
                swal("Succès", "Le secteur a été ajouté", "success");
            })
            .error(function (data) {
                $scope.errors = data;
            });
    };

    // Ouverture modal d'édition
    $scope.edit = function (sector) {
        index = $scope.sectors.indexOf(sector);
        $scope.editedSector = angular.copy(sector);
        $scope.e_errors={};
        Modal.open('editModal');
    };

    // Mise à jour d'un secteur
    $scope.update = function () {
        $scope.e_errors = {};
        Sector.update($scope.editedSector)
            .success(function (data) {
                $scope.editedSector = {};
                $scope.sectors[index] = data.sector;
                Modal.close('editModal');
                swal("Succès", "Le secteur a été modifié", "success");
                index = null;
            })
            .error(function (data) {
                $scope.e_errors = data;
            });
    };

    // Suppression d'un secteur
    $scope.delete = function (sector) {
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            index = $scope.sectors.indexOf(sector);
            Sector.delete(sector.id).success(function (data) {
                $scope.sectors[index] = data.sector;
                swal("Succès", "Le secteur a été supprimé", "success");
                index = null;
            });
        });
    };

    // Restauration d'une source
    $scope.restore = function (source) {
        index = $scope.sources.indexOf(source);
        Sector.restore(source.id).success(function (data) {
            $scope.sources[index] = data.source;
            swal("Succès", "La source a été restaurée", "success");
            index = null;
        });
    };

}]);