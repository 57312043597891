angular.module('UserService', []).factory('User', ['$http', 'Config', function($http, Config) {
    
    return {
        get: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/users',
            });
        },
        listing: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/users/listing',
            });
        },
        getAuth: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/auth',
            });
        },
        store: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/users',
                data: data,
            });
        },
        update: function(data) {
            return $http({
                method: 'PUT',
                url: Config.url() + '/api/users/' + data.id,
                data: data,
            });
        },
        delete: function(id) {
            return $http({
                method: 'DELETE',
                url: Config.url() + '/api/users/' + id,
            });
        },
        restore: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/users/restore/' + id,
            });
        },
        tasks: function(data) {
            return $http({
                method: 'GET',
                url: '/api/users/' + data.id + '/tasks',
            });
        },
    };

}]);