angular.module('InteractionService', []).factory('Interaction', ['$http', 'Config', function($http, Config) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/interactions',
            });
        },
        update: function(data) {
            return $http({
                method: 'PUT',
                url: Config.url() + '/api/interactions/' + data.id,
                data: data,
            });
        },
        store: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/interactions',
                data: data,
            });
        },
        delete: function(id) {
            return $http({
                method: 'DELETE',
                url: Config.url() + '/api/interactions/' + id,
            });
        },
        restore: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/interactions/restore/' + id,
            });
        }
    };

}]);