angular.module('CategoriesController', []).controller('CategoriesController', ['$scope', '$filter', 'Category', 'Config', 'Modal', function($scope, $filter, Category, Config, Modal) {
    var index;
    $scope.colors = Config.getColors();

    // Initialisation d'une nouvelle catégorie
    $scope.initCategory = function () {
        $scope.newCategory = {
            color: $scope.colors[0].value
        };
    };

    // Récupération de la liste des catégories
    $scope.get = function () {
        $scope.loading = true;
        Category.get().success(function (data) {
            $scope.loading = false;
            $scope.categories = data;
        });
    };

    // Ouverture modal de création
    $scope.create = function () {
        $scope.initCategory();
        $scope.errors={};
        Modal.open('createModal');
    };

    // Ajout d'une nouvelle catégorie
    $scope.store = function () {
        $scope.errors = {};
        Category.store($scope.newCategory)
            .success(function (data) {
                Modal.close('createModal');
                $scope.initCategory();

                index = null;
                for (var i=0; i<$scope.categories.length; i++) {
                    if ($scope.categories[i].id == data.category.id) {
                        index = i;
                        break;
                    }
                }

                if (index == null) {
                    $scope.categories.push(data.category);
                } else {
                    $scope.categories[index] = data.category;
                }
                swal("Succès", "La catégorie a été ajoutée", "success");
            })
            .error(function (data) {
                $scope.errors = data;
            });
    };

    // Ouverture modal d'édition
    $scope.edit = function (category) {
        index = $scope.categories.indexOf(category);
        $scope.editedCategory = angular.copy(category);
        $scope.e_errors={};
        Modal.open('editModal');
    };

    // Mise à jour d'une catégorie
    $scope.update = function () {
        $scope.errors = {};
        Category.update($scope.editedCategory)
            .success(function (data) {
                Modal.close('editModal');
                $scope.editedCategorie = {};
                $scope.categories[index] = data.category;
                swal("Succès", "La catégorie a été modifiée", "success");
                index = null;
            })
            .error(function (data) {
                $scope.e_errors = data;
            });
    };

    // Suppression d'une catégorie
    $scope.delete = function (category) {
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            index = $scope.categories.indexOf(category);
            Category.delete(category.id)
                .success(function (data) {
                    $scope.categories[index] = data.category;
                    index = null;
                    swal("Succès", "La catégorie a été supprimée", "success");
                })
                .error(function (data) {
                    swal("Erreur", "Merci de contacter notre équipe technique", "error");
                });
        });
    };

    // Restore a cateogry
    $scope.restore = function (category) {
        index = $scope.categories.indexOf(category);
        Category.restore(category.id).success(function (data) {
            $scope.categories[index] = data.category;
            index = null;
            swal("Succès", "La catégorie a été restaurée", "success");
        });
    };

    // Open swap modal
    $scope.transfer = function (category) {
        $scope.from = category;
        $scope.target = $filter('notDeleted')($scope.categories)[0];
        $scope.t_errors={};
        Modal.open('swapModal');
    };

    // Swap category companies
    $scope.swap = function () {
        Category.swap({old_category_id: $scope.from.id, new_category_id: $scope.target.id})
            .success(function (data) {
                Modal.close('swapModal');
                $scope.t_errors = {};
                $scope.categories = data.categories;
                swal("Succès", "Les sociétés de cette catégorie ont été transférées", "success");
            })
            .error(function (data) {
                $scope.t_errors = data;
            });
    };

}]);