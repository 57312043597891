angular.module('ModalService', []).factory('Modal', ['$http', function($http) {

    return {
        open: function (modal_id) {
            $("body").addClass("modal-open");
            $('#'+modal_id).show();
        },
        close: function (modal_id) {
            $("body").removeClass("modal-open");
            $('#'+modal_id).hide();
        }
    }

}]);