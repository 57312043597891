angular.module('StatisticsController', []).controller('StatisticsController', ['$scope', 'Statistic', 'Category', 'User', 'Event', function($scope, Statistic, Category, User, Event) {

    $scope.stats = [];
    $scope.graph = [];
    $scope.users = [];
    $scope.categories = [];
    $scope.events = [];
    $scope.startDate = {};
    $scope.endDate = {};
    $scope.page = {};

    // Récupération des catégories
    Category.listing().success(function (data) {
        $scope.categories = data.categories;
        $scope.categoriesFilter = angular.copy(data.categories);
        $scope.categoriesFilter.splice(0, 0, {id: '!', designation: 'Toutes'});
    });
    // Récupération des utilisateurs
    User.listing().success(function (data) { $scope.users = data.users; });
    // Récupération des évènements
    Event.listing().success(function (data) {
        $scope.events = data.events;
        $scope.eventsFilter = angular.copy(data.events);
        $scope.eventsFilter.splice(0, 0, {id: '!', designation: 'Tous'});
    });

    // Initialisation des filtres
    $scope.initStatsFilter = function () {
        $scope.filters = {};
        $scope.filters.category = {};
        $scope.filters.category.id = '!';

        $scope.filters.event = {};
        $scope.filters.event.id = '!';

        // Initialisation des dates
        $scope.startDate = moment().startOf('month');
        $scope.endDate = moment().endOf('month');
    };
    $scope.initStatsFilter();

    function callback_watch(){
        if ($scope.page === 'categories') {
            $scope.getStats('Category');
        }
        if ($scope.page === 'events') {
            $scope.getStats('Event');
        }
    }

    $scope.$watch('startDate', function(newValue, oldValue) {
        if (newValue !== oldValue) {
            callback_watch();
        }
    }, true);
    $scope.$watch('endDate', function(newValue, oldValue) {
        if (newValue !== oldValue) {
            callback_watch();
        }
    }, true);

    // Fonction de récupération des statistiques
    $scope.getStats = function(className){
        $scope.loading = true;
        $scope.loadingGraph = true;

        var data = {};
        data.class     = className;
        data.startDate = moment($scope.startDate).format('YYYY/MM/DD');
        data.endDate   = moment($scope.endDate).format('YYYY/MM/DD');

        if (className === 'Category'){
            Statistic.categories(data)
                .success(function (data) {
                    $scope.loading = false;
                    $scope.stats = data;
                });
        }
        if (className === 'Event'){
            Statistic.events(data)
                .success(function (data) {
                    $scope.loading = false;
                    $scope.stats = data;
                });
        }

        Statistic.graph(data)
            .success(function (data) {
                $scope.loadingGraph = false;
                $scope.graph = data;

                angular.forEach($scope.graph['series'], function(value, key) {
                    $scope.graph['series'][key] = moment($scope.graph['series'][key]).format('MMM YYYY');
                });
            });
    };

}]);