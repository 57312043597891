angular.module('EditInteractionController', []).controller('EditInteractionController', ['$scope', '$filter', 'Interaction', 'Modal', 'Event', function($scope, $filter, Interaction, Modal, Event) {

    $scope.editedInteraction = {};
    $scope.addEvent = false;
    var index = null;

    $scope.storeEvent = function () {
        $scope.event_errors = {};
        Event.store($scope.newEvent)
            .success(function (data) {
                $scope.addEvent = false;
                $scope.$parent.events.splice(0, 0, data.event);
                $scope.$parent.eventsFilter.push(data.event);
                $scope.newInteraction.event_id = data.event.id;
                $scope.newEvent = {};
            })
            .error(function (data) {
                $scope.event_errors = data;
            });
    };

    // Ouverture modal d'édition
    $scope.$on('editInteraction', function(event, args) {
        $scope.editedInteraction = args;
        index = $scope.$parent.interactions.indexOf(args);
        if (args.comment != null) {
            $scope.editedInteraction.comment = args.comment.message;
        } else {
            $scope.editedInteraction.comment = null;
        }

        Modal.open('editInteractionModal');
    });

    // Mise à jour d'une intéraction
    $scope.updateInteraction = function () {
        $scope.e_errors = {};

        $scope.editedInteraction.created_at = $filter('date')($scope.editedInteraction.created_at, "yyyy-MM-dd");
        Interaction.update($scope.editedInteraction)
            .success(function (data) {
                Modal.close('editInteractionModal');
                swal("Succès", "L'intéraction a été modifiée", "success");

                $scope.$parent.interactions[index] = (data.interaction);
                $scope.$parent.interactions = $filter('orderBy')($scope.$parent.interactions, '-created_at');
            })
            .error(function (data) {
                $scope.e_errors = data;
            });
    };
}]);