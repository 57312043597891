angular.module('CreateCompaniesController', []).controller('CreateCompaniesController', ['$scope', '$filter', 'Company', 'Category', 'User', 'Source', 'Sector', 'Modal', 'Config', function($scope, $filter, Company, Category, User, Source, Sector, Modal, Config) {

    /***********************************
     ---- Init ----
     ***********************************/
    $scope.employees = Config.getEmployees();

    $scope.company = {};
    $scope.company.employees = 1;
    $scope.categories = [];
    $scope.users = [];
    $scope.sources = [];
    $scope.sectors = [];

    // Retrieve all model needed
    Category.listing().success(function (data) {
        $scope.categories = data.categories;
        if ($scope.categories.length) {
            $scope.company.category_id = $scope.categories[0].id;
        }
    });
    User.listing().success(function (data) {
        $scope.users = data.users;
        User.getAuth().success(function (data) {
            $scope.userConnected = data;
            if ($scope.userConnected.id == 1) {
                $scope.company.user_id = $scope.users[0].id;
            } else {
                $scope.company.user_id = $scope.userConnected.id;
            }
        });
    });
    Source.listing().success(function (data) {
        $scope.sources = data.sources;
        if (!$scope.sources.length) {
            $scope.newSource = true;
        } else {
            $scope.company.source_id = $scope.sources[0].id;
        }
    });
    Sector.listing().success(function (data) {
        $scope.sectors = data.sectors;
        if (!$scope.sectors.length) {
            $scope.newSector = true;
        } else {
            $scope.company.sector_id = $scope.sectors[0].id;
        }
    });


    // Init a new company
    $scope.initCompany = function () {
        $scope.company = {
            user_id: $scope.userConnected.id == 1 ? $scope.users[0].id : $scope.userConnected.id,
            category_id: $scope.categories[0].id,
            source_id: $scope.sources[0].id,
            sector_id: $scope.sectors[0].id,
            employees: 1
        };

        $scope.newSource = false;
        $scope.newSector = false;
        $scope.source = {};
        $scope.sector = {};
    };

    // Store a new company
    $scope.store = function () {
        $scope.errors = {};
        Company.store($scope.company)
            .success(function (data) {
                $scope.errors = {};
                swal({
                    title: "Succès",
                    text: "La société a été ajoutée. Vous allez être redirigé vers la page d'information",
                    type: "success"
                }, function(){
                    window.location.replace(Config.url() + "/companies/" + data.company.id);
                });
            })
            .error(function (data) {
                $scope.errors = data;
            });
    };

    // Store a new sector
    $scope.addSector = function () {
        $scope.sector_errors = {};
        Sector.store($scope.sector)
            .success(function (data) {
                $scope.newSector = false;
                $scope.sectors.push(data.sector);
                $scope.company.sector_id = data.sector.id;
                $scope.sector = {};
            })
            .error(function (data) {
                $scope.sector_errors = data;
            });
    };

    // Store a new sector
    $scope.addSource = function () {
        $scope.source_errors = {};
        Source.store($scope.source)
            .success(function (data) {
                $scope.newSource = false;
                $scope.sources.push(data.source);
                $scope.company.source_id = data.source.id;
                $scope.source = {};
            })
            .error(function (data) {
                $scope.source_errors = data;
            });
    };

    // Open modal and store catégories
    $scope.colors = Config.getColors();

    $scope.initCategory = function () {
        $scope.newCategory = {
            color: $scope.colors[0].value
        };
    };

    $scope.open = function () {
        $scope.initCategory();
        $scope.errors={};
        Modal.open('createModal');
    };
    
    $scope.storeCategory = function () {
        $scope.errors = {};
        Category.store($scope.newCategory)
            .success(function (data) {
                Modal.close('createModal');
                $scope.initCategory();
                $scope.errors = {};
                $scope.categories.push(data.category);
                $scope.company.category_id = data.category.id;
                swal("Succès", "La catégorie a été ajoutée", "success");
            })
            .error(function (data) {
                $scope.errors = data;
            });
    };

}]);