angular.module('MainController', []).controller('MainController', ['$scope', function($scope) {

    // Retrieve element by id
    $scope.getItemById = function (id, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (angular.equals(list[i].id, id)) {
                return list[i];
            }
        }
        return 'Pas de résultat';
    };

    // Retrieve element by designation
    $scope.getItemByDesignation = function (designation, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (angular.equals(list[i].designation, designation)) {
                return list[i];
            }
        }
        return 'Pas de résultat';
    };


    // Options par défaut des datepickers
    $scope.datepickerOptions = {
        monthsFull: [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
        monthsShort: [ 'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec' ],
        weekdaysFull: [ 'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi' ],
        weekdaysShort: [ 'Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam' ],
        today: 'Aujourd\'hui',
        clear: '',
        close: 'Fermer',
        firstDay: 1,
        format: 'dd mmmm yyyy',
        selectMonths: true,
        selectYears: true
    };

    // Paramètres pour multi-select
    $scope.settingsUsers = {
        enableSearch: true,
        idProp: 'id',
        displayProp: "full_name",
        externalIdProp: 'id',
        buttonClasses: 'multi-select',
        scrollable: true,
        scrollableHeight: 200,
        smartButtonMaxItems: 3
    };

    $scope.translateSettingsUsers = {
        buttonDefaultText: 'Selection des utilisateurs',
        checkAll: 'Tous',
        uncheckAll: 'Aucun',
        searchPlaceholder: 'Rechercher...',
        dynamicButtonTextSuffix: 'utilisateur(s) '
    };

}]);