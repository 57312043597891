angular.module('RolesController', []).controller('RolesController', ['$scope', 'Role', function($scope, Role) {

    $scope.activeTab = 1;
    $scope.permissionsForUpdate = [];

    // Initialise un nouveau role
    $scope.initNewRole = function () {
        $scope.newRole = {};
    };

    // Ouverture de la modal de création
    $scope.openCreate = function () {
        $scope.initNewRole();
        $scope.errors={};
        $('#createModal').show();
    };

    // Ouverture de la modal d'édition
    $scope.openEdit = function (role) {
        $scope.editedRole = angular.copy(role);
        $scope.e_errors={};
        $('#editModal').show();
    };

    // Récupération de la liste des rôles
    $scope.get = function () {
        $scope.loading = true;
        Role.get().success(function (data) {
            $scope.loading = false;
            $scope.roles = data;

            $scope.selectedRole = angular.copy($scope.roles[0]);
            $scope.getRolePermissions($scope.selectedRole.id);
        });
    };

    // Modification d'un rôle
    $scope.update = function (editedRole) {
        $scope.errors = {};
        editedRole.display_name = editedRole.name;
        Role.update(editedRole)
            .success(function (data) {
                $('#editModal').hide();
                $scope.editedRole = {};
                $scope.e_errors = {};
                swal("Rôle sauvegardé", "", "success");
                $scope.get();
            })
            .error(function (data) {
                $scope.e_errors = data;
            });
    };

    // Insertion d'un nouveau rôle
    $scope.store = function (newRole) {
        $scope.errors = {};
        newRole.display_name = newRole.name;
        Role.store(newRole)
            .success(function (data) {
                $scope.initNewRole();
                $('#createModal').hide();
                $scope.errors = {};
                swal("Rôle sauvegardé", "Vous pouvez maintenant affecté des utilisateurs à ce rôle depuis la liste des utilisateurs", "success");
                $scope.get();
            })
            .error(function (data) {
                $scope.errors = data;
            });
    };

    // Suppression d'un rôle
    $scope.delete = function (roleId) {
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            Role.delete(roleId).success(function (data) {
                swal("Rôle supprimé", "", "success");
                $scope.get();
            });
        });

    };

    // Récupération des permissions
    $scope.getPermissions = function () {
        Role.getPermissions().success(function (data) {
            $scope.permissions = data;
        });
    };

    // Récupération des permissions pour un rôle donné
    $scope.getRolePermissions = function (roleId) {
        Role.getRolePermissions(roleId).success(function (data) {
            $scope.permissionsForUpdate = data;
        });
    };

    // Renvoie vers la liste des permissions du rôle
    $scope.showPermissions = function (role) {
        Role.getRolePermissions(role.id).success(function (data) {
            $scope.permissionsForUpdate = data;
        });
        $scope.activeTab = 2;
        $scope.selectedRole = role;
    };
    
    $scope.updatePermissions = function (roleId) {
        Role.updatePermissions(roleId, $scope.permissionsForUpdate)
            .success(function (data) {
                swal("Permissions sauvegardées", "", "success");
                Role.getRolePermissions(roleId).success(function (data) {
                    $scope.permissionsForUpdate = data;
                });
            })
    };

    $scope.updateSyncPermissions = function (permission){
        var index = $scope.permissionsForUpdate.indexOf(permission.id);
        if (index > -1) {
            $scope.permissionsForUpdate.splice(index, 1);
        } else {
            $scope.permissionsForUpdate.push(permission.id);
        }
        $scope.updatePermissions($scope.selectedRole.id);
        index = null;
    };
}]);