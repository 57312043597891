angular.module('DashboardController', []).controller('DashboardController', ['$scope', 'Task', 'Config', 'Modal', 'Dashboard', 'User', 'Company', 'Event', function($scope, Task, Config, Modal, Dashboard, User, Company, Event) {

    $scope.inController = 'dashboard';

    $scope.task = {};
    $scope.reduceActions = true;
    $scope.linkInTask = true;

    Company.listing().success(function (data) { $scope.companies = data.companies; });
    Event.listing().success(function (data) { $scope.events = data.events; });
    User.listing().success(function (data) { $scope.users = data.users; });

    $scope.initDashboard = function () {

        // Récupération des intéractions
        $scope.loadingInterac = true;
        Dashboard.interactions().success(function (data) {
            $scope.interactions = data.interactions;
            $scope.loadingInterac = false;
        });

        // Récupération des tâches
        $scope.loadingTasks = true;
        Dashboard.tasks().success(function (data) {
            $scope.tasks = data.tasks;
            $scope.loadingTasks = false;
        });
    };

    $scope.initDashboard();

    // Ajout d'une nouvelle tâche
    $scope.storeTask = function () {
        $scope.t_errors = {};
        Task.store($scope.task)
            .success(function (data) {
                $scope.task = {};
                swal({
                    title: "Succès",
                    text: "La tâche a été ajoutée. Vous allez être redirigé vers sa page de visualisation",
                    type: "success"
                }, function(){
                    window.location.replace(Config.url() + '/tasks#/?t=' + data.task.id);
                });
            })
            .error(function (data) {
                $scope.t_errors = data;
            });
    };

    $scope.initInteraction = function () {
        $scope.newInteraction.created_at = new Date();
    };

    // Ouverture modal de création d'une intéraction
    $scope.createInteraction = function () {
        $scope.$broadcast('createNewInteraction');
    };

}]);