angular.module('ConfigService', []).factory('Config', function() {

    var colors = [
        {value: 'bleu', label: 'Bleu'},
        {value: 'cyan', label: 'Cyan'},
        {value: 'gris', label: 'Gris'},
        {value: 'jaune', label: 'Jaune'},
        {value: 'noir', label: 'Noir'},
        {value: 'orange', label: 'Orange'},
        {value: 'rouge', label: 'Rouge'},
        {value: 'rose', label: 'Rose'},
        {value: 'vert', label: 'Vert'},
        {value: 'violet', label: 'Violet'}
    ];

    var employees = [
        {id: 1, label: 'Inconnu'},
        {id: 2, label: 'Très petite entreprise'},
        {id: 3, label: 'Petite et moyenne entreprise'},
        {id: 4, label: 'Grande entreprise'},
    ];

    var base_url = $('body').data('url');

    return {
        getColors: function () {
            return colors;
        },
        getEmployees: function () {
            return employees;
        },
        url: function () {
            return base_url;
        },
    };

});