angular.module('StatisticService', []).factory('Statistic', ['$http', 'Config', function($http, Config) {

    return {
        categories: function(data) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/statistics/categories',
                params: data,
            });
        },
        events: function(data) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/statistics/events',
                params: data,
            });
        },
        graph: function(data) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/statistics/graph',
                params: data,
            });
        },
    };

}]);