angular.module('ContactsController', []).controller('ContactsController', ['$scope', 'Contact', 'ContactType', 'Modal', function($scope, Contact, ContactType, Modal) {

    $scope.contact = {};
    $scope.fonctions = [];
    $scope.associateAddress = false;

    $scope.newContactTypeEdit = false;
    
    // Initialisation d'un nouveau contact
    $scope.initContact = function () {
        $scope.associateAddress = false;
        $scope.contact = {};
        if (!$scope.fonctions.length) {
            $scope.newContactType = true;
        } else {
            $scope.newContactType = false;
            $scope.contact.contact_type_id = $scope.fonctions[0].id;
        }
    };

    // Récupération de la liste des fonctions
    ContactType.listing()
        .success(function (data) {
            $scope.fonctions = data;
            if (!$scope.fonctions.length) {
                $scope.newContactType = true;
            } else {
                $scope.contact.contact_type_id = $scope.fonctions[0].id;
            }
        });

    // Ouverture de la modal de visualisation
    $scope.show = function (contact) {
        $scope.displayedContact = contact;
        Modal.open('showModalContact');
    };

    // Ouverture de la modal de création
    $scope.create = function () {
        $scope.newContactType = false;
        $scope.initContact();
        $scope.errors={};
        Modal.open('createModalContact');
    };

    // Ouverture de la modal d'édition
    $scope.edit = function (contact, index) {
        $scope.newContactType = false;
        $scope.index = index;
        $scope.editedContact = angular.copy(contact);
        $scope.e_errors={};
        if ($scope.editedContact.address_id) {
            $scope.associateAddress = true;
        } else {
            $scope.associateAddress = false;
        }
        Modal.open('editModalContact');
    };

    // Ajout d'un type de contact
    $scope.addContactType = function () {
        ContactType.store($scope.contactType)
            .success(function (data) {
                $scope.newContactType = false;
                $scope.contactType = {};
                $scope.contact_type_errors = {};
                $scope.fonctions = data.contactTypes;
                $scope.contact.contact_type_id = data.id;
                $scope.editedContact.contact_type_id = data.id;
            })
            .error(function (data) {
                $scope.contact_type_errors = data;
            });
    };

    // Modification d'un contact
    $scope.update = function () {
        if (!$scope.associateAddress) {
            $scope.editedContact.address_id = null;
        }

        Contact.update($scope.editedContact)
            .success(function (data) {
                $scope.editedContact = {};
                $scope.e_errors = {};
                $scope.$parent.contacts[$scope.index] = data.contact;
                $scope.newContactTypeEdit = false;
                $scope.associateAddress = false;
                Modal.close('editModalContact');
                swal("Succès", "Le contact a été modifé", "success");
            })
            .error(function (data) {
                $scope.e_errors = data;
            });
    };

    // Ajout d'un nouveau contact
    $scope.store = function () {
        $scope.contact.company_id = $scope.$parent.company.id;
        if (!$scope.associateAddress) {
            $scope.contact.address_id = null;
        }
        Contact.store($scope.contact)
            .success(function (data) {
                $scope.initContact();
                $scope.errors = {};
                $scope.$parent.contacts.push(data.contact);
                Modal.close('createModalContact');
                swal("Succès", "Le contact a été ajouté", "success");
            })
            .error(function (data) {
                $scope.errors = data;
            });
    };

    // Suppression d'un contact
    $scope.delete = function (index) {
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            Contact.delete($scope.$parent.contacts[index].id).success(function (data) {
                $scope.$parent.contacts.splice(index, 1);
                swal("Succès", "Le contact a été supprimé", "success");
            });
        });
    };

}]);