angular.module('IndexCompaniesController', []).controller('IndexCompaniesController', ['$scope', '$filter', 'Company', 'Category', 'User', 'Source', 'Sector', 'Config', function($scope, $filter, Company, Category, User, Source, Sector, Config) {

    var index = null;
    /***********************************
     ---- Filters ----
     ***********************************/
    $scope.orderValues = [
        {value: '-id', label: 'Date de création'},
        {value: 'name', label: 'Nom'},
        {value: 'category_id', label: 'Catégorie'},
    ];

    $scope.initCompanyFilter = function () {
        $scope.companyFilter = {};
        $scope.companyFilter = {
            sector_id: '!',
            source_id: '!',
            category_id: '!',
            user_id: '!',
        };

        $scope.filters = {};
        $scope.filters = {
            order: '-id',
            reverse: false
        };
        $scope.deletedFilter = '!';
        $scope.nameFilter = '';
    };

    /***********************************
    ---- Init ----
    ***********************************/
    $scope.employees = Config.getEmployees();
    $scope.initCompanyFilter();

    $scope.companies = [];
    $scope.categories = [];
    $scope.users = [];
    $scope.sources = [];
    $scope.sectors = [];

    $scope.paginationChoice = [ 50, 100, 150, 200];
    $scope.perPage = 100;

    // Retrieve all model needed
    Category.listing().success(function (data) {
        $scope.categories = data.categories;
        $scope.categories.splice(0, 0, {id: '!', designation: 'Toutes'});
    });
    User.listing().success(function (data) {
        $scope.users = data.users;
        $scope.users.splice(0, 0, {id: '!', full_name: 'Tous'});
    });
    Source.listing().success(function (data) {
        $scope.sources = data.sources;
        $scope.sources.splice(0, 0, {id: '!', designation: 'Toutes'});
    });
    Sector.listing().success(function (data) {
        $scope.sectors = data.sectors;
        $scope.sectors.splice(0, 0, {id: '!', designation: 'Tous'});
    });

    // Retrieve list of companies
    $scope.get = function () {
        $scope.loading = true;
        Company.get().success(function (data) {
            $scope.loading = false;
            $scope.companies = data;
        });
    };

    // Suppression d'une société
    $scope.delete = function (company) {
        swal({
            title: "Êtes vous sur ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "Annuler" ,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Supprimer",
            closeOnConfirm: false
        }, function(){
            index = $scope.companies.indexOf(company);
            Company.delete($scope.companies[index].id).success(function (data) {
                $scope.companies[index] = data.company;
                index = null;
                swal("Succès", "La société a été supprimée", "success");
            });
        });

    };

    // Restore a company
    $scope.restore = function (company) {
        index = $scope.companies.indexOf(company);
        Company.restore($scope.companies[index].id).success(function (data) {
            $scope.companies[index] = data.company;
            index = null;
            swal("Succès", "La société a été restaurée", "success");
        });
    };

}]);