angular.module('ActivitiesController', []).controller('ActivitiesController', ['scope', function($scope) {

    $scope.showForm = false;

    $scope.displayForm = function () {
        $scope.showForm = true;
    };

    $scope.hideForm = function () {
        $scope.showForm = false;
    };

}]);