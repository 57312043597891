angular.module('RoleService', []).factory('Role', ['$http', 'Config', function($http, Config) {

    return {
        get: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/roles',
            });
        },
        getPermissions: function() {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/permissions',
            });
        },
        getRolePermissions: function(id) {
            return $http({
                method: 'GET',
                url: Config.url() + '/api/permissions/' + id,
            });
        },
        store: function(data) {
            return $http({
                method: 'POST',
                url: Config.url() + '/api/roles',
                data: data,
            });
        },
        update: function(data) {
            return $http({
                method: 'PUT',
                url: Config.url() + '/api/roles/' + data.id,
                data: data,
            });
        },
        updatePermissions: function(id, permissions) {
            return $http({
                method: 'PUT',
                url: Config.url() + '/api/permissions/' + id,
                data: {
                    id: id,
                    permissions: permissions,
                },
            });
        },
        delete: function(id) {
            return $http({
                method: 'DELETE',
                url: Config.url() + '/api/roles/' + id,
            });
        },
    };

}]);